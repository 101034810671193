import { useState } from 'react';
import './Main.css';
import { ReactComponent as Barbells } from '../../assets/barbells.svg' 
import Weights from '../../components/Weights/Weights'

function Main() {
  const [total, setTotal] = useState(21);
  const updateTotal = (value) => {
    setTotal(Math.round((total + value) * 10) / 10)
  }
  return (
    <main className="main">
      <h2><span id="total">{total}</span> kg</h2>
      <Barbells className="barbells"/>
      <Weights updateTotal={updateTotal}/>
    </main>
  );
}

export default Main;
