import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>v 1.0.0</p>
      •
      <p>Created by <a href="https://filipiec.pl">Michał Filipiec</a></p>
    </footer>
  );
}

export default Footer;
