import { useState } from 'react';
import './Weights.css';
import Weight from '../Weight/Weight'

function Button({value, color, updateTotal}) {
  const [selected, setSelected] = useState(false);
  const toogleBtn = () => {
    const sum = value * 2;
    
    updateTotal(selected ? -sum : sum)
    setSelected(!selected);
  };
  return (
    <button 
      className={selected ? 'weights__item weights__item--selected' : 'weights__item' }
      onClick={toogleBtn}
    >
      <Weight color={color}/>
      <span>{value} kg</span>
    </button>
  )
}

function Weights({updateTotal}) {  
  const weightsTable = [
    [1.1, 'black'],
    [3, 'black'],
    [5.6, 'grey'],
    [5.7, 'black'],
    [10.7, 'black'],
    [17.4, 'green'],
    [17.75, 'blue'],
    [23.4, 'red'],
    [26, 'black']
  ];

  return (
    <div className="weights">
      {weightsTable.map(([value, color]) => 
        <Button value={value} color={color} updateTotal={updateTotal}/>
      )}
    </div>
  );
}

export default Weights;
